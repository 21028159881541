










































































































































































































































































.enInfo{
    height: 100vh;
    overflow: hidden;
    .main{
        min-height: calc(100vh - 10.875rem);
        background-image: url("../../assets/humanResources/registerBackground.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
        display: flex;
        justify-content: center;
        align-items: center;
        .form-wrap{
            width: 54.125rem;
            height: 38rem;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
            border-radius: 8px;
            padding: 2.75rem 6.625rem;
            .title{
                font-size: 1.125rem;
                font-weight: 600;
                color: #333333;
                text-align: center;
            }
            // 定义表单样式
            /deep/.el-form{
                min-width: 31.25rem;
                margin-top: 1.875rem;
                .flex-item{
                    display: flex;
                    justify-content: space-between;
                    .el-form-item{
                        flex-grow: 1;
                    }
                }
                .el-form-item__label{
                    height: 2.25rem;
                    line-height: 2.25rem;
                }
                .el-input--small .el-input__inner{
                    height: 2.25rem;
                    line-height: 2.25rem;
                }
                .el-input__suffix{
                    line-height: 2.375rem;
                }
                .agree{
                    display: flex;
                    .el-checkbox{
                        margin-right: 3px;
                        margin-top: -0.4375rem;
                    }
                    .detail{
                        font-size: .75rem;
                        color: #DDDDDD;
                        line-height: normal;
                    }
                }
                // 创建按钮
                .btn-wrap{
                    text-align: center;
                    margin-top: 2.25rem;
                    .build-btn{
                        width: 15.375rem;
                        border: none;
                        background: #2878FF;
                        border-radius: 2px;
                        font-size: .875rem;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    // 滚动条整体部分
    ::-webkit-scrollbar {
        width:10px;
        border-radius: 0px;
    }
    // 内层轨道 
    ::-webkit-scrollbar-track-piece{
        border-radius: 4px;
        background: white;
    }
    // 滚的条
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: #e7e7e7;
    }
}
