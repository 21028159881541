.enInfo {
  height: 100vh;
  overflow: hidden;
}
.enInfo .main {
  min-height: calc(100vh - 10.875rem);
  background-image: url("../../assets/humanResources/registerBackground.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.enInfo .main .form-wrap {
  width: 54.125rem;
  height: 38rem;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 2.75rem 6.625rem;
}
.enInfo .main .form-wrap .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
  text-align: center;
}
.enInfo .main .form-wrap /deep/ .el-form {
  min-width: 31.25rem;
  margin-top: 1.875rem;
}
.enInfo .main .form-wrap /deep/ .el-form .flex-item {
  display: flex;
  justify-content: space-between;
}
.enInfo .main .form-wrap /deep/ .el-form .flex-item .el-form-item {
  flex-grow: 1;
}
.enInfo .main .form-wrap /deep/ .el-form .el-form-item__label {
  height: 2.25rem;
  line-height: 2.25rem;
}
.enInfo .main .form-wrap /deep/ .el-form .el-input--small .el-input__inner {
  height: 2.25rem;
  line-height: 2.25rem;
}
.enInfo .main .form-wrap /deep/ .el-form .el-input__suffix {
  line-height: 2.375rem;
}
.enInfo .main .form-wrap /deep/ .el-form .agree {
  display: flex;
}
.enInfo .main .form-wrap /deep/ .el-form .agree .el-checkbox {
  margin-right: 3px;
  margin-top: -0.4375rem;
}
.enInfo .main .form-wrap /deep/ .el-form .agree .detail {
  font-size: .75rem;
  color: #DDDDDD;
  line-height: normal;
}
.enInfo .main .form-wrap /deep/ .el-form .btn-wrap {
  text-align: center;
  margin-top: 2.25rem;
}
.enInfo .main .form-wrap /deep/ .el-form .btn-wrap .build-btn {
  width: 15.375rem;
  border: none;
  background: #2878FF;
  border-radius: 2px;
  font-size: .875rem;
  color: #FFFFFF;
}
.enInfo ::-webkit-scrollbar {
  width: 10px;
  border-radius: 0px;
}
.enInfo ::-webkit-scrollbar-track-piece {
  border-radius: 4px;
  background: white;
}
.enInfo ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #e7e7e7;
}
